import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { 
        GET_EWALLET_SETTING_DETAILS,
        PUT_EWALLET_SETTING_DETAILS,
        POST_EWALLET_SETTING_DETAILS,
        GET_AGENT_INCENTIVES,
        ADD_AGENT_INCENTIVE,
        GET_USER_INCENTIVES,
        ADD_USER_INCENTIVE,
     } = URLS;
    return {
        getWalletDetails: (body = {}, method = 'get', query) =>
            performRequest(method ? method : 'get', GET_EWALLET_SETTING_DETAILS, body),
        postWalletDetails: (body = {}, method = 'post', query) =>
            performRequest(method ? method : 'post', POST_EWALLET_SETTING_DETAILS, body),
        putWalletDetails: (body = {}, method = 'put', query) =>
            performRequest(method ? method : 'put', PUT_EWALLET_SETTING_DETAILS.replace(':_id',body._id), body),
        getAgentIncentives: (body = {}, method = 'get', query) =>
            performRequest(method ? method : 'get', GET_AGENT_INCENTIVES, body),
        addAgentIncentive: (body = {}, method = 'post', query) =>
            performRequest(method ? method : 'post', ADD_AGENT_INCENTIVE, body),
        getUserIncentives: (body = {}, method = 'get', query) =>
            performRequest(method ? method : 'get', GET_USER_INCENTIVES, body),
        addUserIncentive: (body = {}, method = 'post', query) =>
            performRequest(method ? method : 'post', ADD_USER_INCENTIVE, body),
    }
}




